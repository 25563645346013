var exports = {};

exports = function (hljs) {
  var LITERALS = "true false yes no null";
  var keyPrefix = "^[ \\-]*";
  var keyName = "[a-zA-Z_][\\w\\-]*";
  var KEY = {
    className: "attr",
    variants: [{
      begin: keyPrefix + keyName + ":"
    }, {
      begin: keyPrefix + "\"" + keyName + "\"" + ":"
    }, {
      begin: keyPrefix + "'" + keyName + "'" + ":"
    }]
  };
  var TEMPLATE_VARIABLES = {
    className: "template-variable",
    variants: [{
      begin: "{{",
      end: "}}"
    }, // jinja templates Ansible
    {
      begin: "%{",
      end: "}"
    } // Ruby i18n
    ]
  };
  var STRING = {
    className: "string",
    relevance: 0,
    variants: [{
      begin: /'/,
      end: /'/
    }, {
      begin: /"/,
      end: /"/
    }, {
      begin: /\S+/
    }],
    contains: [hljs.BACKSLASH_ESCAPE, TEMPLATE_VARIABLES]
  };
  return {
    case_insensitive: true,
    aliases: ["yml", "YAML", "yaml"],
    contains: [KEY, {
      className: "meta",
      begin: "^---s*$",
      relevance: 10
    }, {
      // multi line string
      className: "string",
      begin: "[\\|>] *$",
      returnEnd: true,
      contains: STRING.contains,
      // very simple termination: next hash key
      end: KEY.variants[0].begin
    }, {
      // Ruby/Rails erb
      begin: "<%[%=-]?",
      end: "[%-]?%>",
      subLanguage: "ruby",
      excludeBegin: true,
      excludeEnd: true,
      relevance: 0
    }, {
      // local tags
      className: "type",
      begin: "!" + hljs.UNDERSCORE_IDENT_RE
    }, {
      // data type
      className: "type",
      begin: "!!" + hljs.UNDERSCORE_IDENT_RE
    }, {
      // fragment id &ref
      className: "meta",
      begin: "&" + hljs.UNDERSCORE_IDENT_RE + "$"
    }, {
      // fragment reference *ref
      className: "meta",
      begin: "\\*" + hljs.UNDERSCORE_IDENT_RE + "$"
    }, {
      // array listing
      className: "bullet",
      begin: "^ *-",
      relevance: 0
    }, hljs.HASH_COMMENT_MODE, {
      beginKeywords: LITERALS,
      keywords: {
        literal: LITERALS
      }
    }, hljs.C_NUMBER_MODE, STRING]
  };
};

export default exports;